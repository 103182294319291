<script>
import VProjectReportGroup from "./components/VProjectReportGroup";
import ProjectFlagService from "@/services/ProjectFlagService";

export default {
    components: { VProjectReportGroup },
    data() {
        return {
            loading: true,
            projects: {}
        };
    },

    async created() {
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await ProjectFlagService.GET_flagReport();
                this.projects = data.data;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <div class="v-planning-report">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper" v-if="true">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <v-project-report-group
                            :title="$t('project.group_on_track')"
                            normal
                            :flags="projects.normal"
                        />
                        <v-project-report-group
                            alarm="orange"
                            :title="$t('project.group_at_risk')"
                            :flags="projects.orange"
                        />
                        <v-project-report-group
                            alarm="red"
                            :title="$t('project.group_realized_risk')"
                            :flags="projects.red"
                        />
                    </template>

                    <card v-if="false">
                        <pre>
## Reports with flag

- one group project on track: projects without any flagging
- projects at risk: projects flagged (change timeline is active) or projects that were flagged with orange
- projects with realized risk: red flag

Columns:

- Name
- Type *(solar, wind etc.)
- Milestone column (same component as previous)
- Comments to flags

Removal of flags: that should happen on the project level. Who should be able to remove flag? King’s Group.
</pre
                        >
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Management of project milestones in a project (LOCAL CONTEXT)
 */
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import VEditProjectMilestoneForm from "@/components/Forms/VEditProjectMilestoneForm";
import VAddProjectMilestoneForm from "@/components/Forms/VAddProjectMilestoneForm";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VMilestones",
    props: {
        project_id: {}
    },
    components: { VEditProjectMilestoneForm, VAddProjectMilestoneForm },
    data() {
        return {
            loading: true,
            p_milestones: [],
            showEditModal: false,
            showAddModal: false,
            editContext: {}
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * Edit a project milestone
         * @param {} ctx
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.showEditModal = true;
        },
        /**
         * Fetch data (all project_milestones)
         */
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await ProjectMilestoneService.GET({
                    project_id: this.project_id
                });
                this.p_milestones = data.data;
            } catch (e) {
                console.log("fd e", e);
            }
            this.loading = false;
        },
        /**
         * After editing of p_milestone
         */
        closeRefresh() {
            this.showAddModal = false;
            this.showEditModal = false;
            this.fetchData();
        },
        async flipStatus(project_milestone) {
            try {
                const { data } = await ProjectMilestoneService.PUT_flipStatus(
                    project_milestone.id
                );

                this.p_milestones.forEach((pm, idx) => {
                    if (pm.id === data.data.id) {
                        this.p_milestones.splice(
                            idx,
                            1,
                            Cloner.clone(data.data)
                        );
                    }
                });
            } catch (e) {}
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <card body-classes="px-0">
                    <div class="mx-4">
                        <div class="row d-flex align-items-center">
                            <div class="col-8">
                                <p>
                                    {{
                                        $t(
                                            "project_milestones.main_view_help_text"
                                        )
                                    }}
                                </p>
                            </div>
                            <div class="col-4 text-right" v-if="false">
                                <base-button
                                    type="primary"
                                    size="sm"
                                    class="mb-4"
                                    @click="showAddModal = true"
                                    >{{
                                        $t(
                                            "project_milestones.btn_add_project_milestone"
                                        )
                                    }}</base-button
                                >
                            </div>
                        </div>
                    </div>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="p_milestones.length > 0">
                        <div class="table-responsive">
                            <v-table class="table table-mg table-hover">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("milestones.tbl_hdr_name")
                                        }}</v-th>
                                        <v-th class="text-center">{{
                                            $t("milestones.tbl_hdr_status")
                                        }}</v-th>
                                        <v-th v-if="false">{{
                                            $t(
                                                "milestones.tbl_hdr_internal_note"
                                            )
                                        }}</v-th>
                                        <v-th
                                            class="text-right"
                                            v-if="false"
                                        ></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="m in p_milestones" :key="m.id">
                                        <v-td>{{ m.name }}</v-td>
                                        <v-td class="text-center">
                                            <span
                                                :class="{
                                                    'text-success':
                                                        m.status_timereg === 1,
                                                    'text-danger':
                                                        m.status_timereg !== 1
                                                }"
                                                >{{
                                                    m.status_timereg_name
                                                }}</span
                                            >
                                            <i
                                                class="far fa-redo m-click ml-2"
                                                @click="flipStatus(m)"
                                            ></i>
                                        </v-td>
                                        <v-td v-if="false">
                                            <v-newlines :text="m.comment" />
                                        </v-td>
                                        <v-td class="text-right" v-if="false">
                                            <i
                                                class="far fa-fw fa-lg fa-pencil m-click"
                                                @click="setContext(m)"
                                            ></i>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                    </template>

                    <div class="mx-4 text-center alert alert-warning" v-else>
                        {{
                            $t(
                                "project_milestones.no_milestones_found_in_the_project"
                            )
                        }}
                    </div>
                </card>
            </div>
        </div>
        <portal to="modals">
            <v-edit-project-milestone-form
                v-if="showEditModal"
                :context="editContext"
                @close-refresh="closeRefresh"
                @close="showEditModal = false"
            />
            <v-add-project-milestone-form
                v-if="showAddModal"
                :project-id="project_id"
                @close-refresh="closeRefresh"
                @close="showAddModal = false"
            />
        </portal>
    </div>
</template>

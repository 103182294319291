<script>
import ProjectService from "@/services/ProjectService";
import MunicipalityService from "@/services/MunicipalityService";
import UserService from "@/services/UserService";
import VFilters from "@/components/Search/VFilters";
import AppCfg from "@/mixins/AppCfg";
import VPortfolioStage from "./components/VPortfolioStage";

export default {
    mixins: [AppCfg],
    components: { VFilters, VPortfolioStage },
    data() {
        return {
            loading: true,
            projects: [],
            meta: {},
            apiFilterView: "",
            municipalities: [],
            users: []
        };
    },
    computed: {
        optTypes() {
            let d = this.appCfg.Projects.project_type;            
            return { ...{ "": this.$t("planning.all_types") }, ...d };
        },
        optStatuses() {
            let d = this.appCfg.Projects.status;
            return { ...{ "": this.$t("planning.all_statuses") }, ...d };
        }
    },
    async created() {
        await this.fetchMunicipalities();
        await this.fetchUsers();
        await this.fetchData();
        this.loading = false;
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                const { data } = await ProjectService.GET_planningPortfolio(
                    params
                );
                this.projects = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd de", e);
            }
        },
        async fetchMunicipalities() {
            try {
                const { data } = await MunicipalityService.GET_dropdown({
                    preset: "pm"
                });
                this.municipalities = data.data;
                this.municipalities.unshift({
                    value: "",
                    label: this.$t("planning.all_municipalities")
                });
            } catch (e) {
                console.log("f mun e", e);
            }
        },
        async fetchUsers() {
            try {
                const r = await UserService.dropdown({ preset: "pm" });
                this.users = r.data.data;
                this.users.unshift({
                    value: "",
                    label: this.$t("planning.all_users")
                });
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
        },
        initSearch() {
            this.apiSearchFilter = {
                responsible_user_id: "",
                municipality_id: "",
                project_type: "",
                status: "",
                search: ""
            };
        }
    }
};
</script>

<template>
    <div class="v-planning-portfolio">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="
                            $t('projects.placeholder_search_in_projects')
                        "
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.project_type"
                                    :placeholder="$t('form_elems.project_type')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="optTypes"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.status"
                                    :placeholder="
                                        $t('form_elems.project_status')
                                    "
                                    type="multiselect"
                                    :multiple="false"
                                    :options="optStatuses"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="
                                        apiSearchFilter.responsible_user_id
                                    "
                                    :placeholder="
                                        $t('form_elems.project_manager_PM')
                                    "
                                    type="multiselect"
                                    :multiple="false"
                                    :options="users"
                                />
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.municipality_id"
                                    :placeholder="$t('form_elems.municipality')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="municipalities"
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>

                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader v-if="loading" :loading="loading" />
                        <template v-else-if="Object.keys(projects).length > 0">
                            <div
                                class="v-portfolio-stage-container"
                                v-for="stage in meta.stages"
                                :key="stage.stage_id"
                            >
                                <h1 class="mx-4">{{ stage.name }}</h1>

                                <v-portfolio-stage
                                    :projects="projects[stage.stage_id]"
                                />
                            </div>
                        </template>

                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("project.no_projects_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-portfolio-stage-container {
    margin-bottom: 50px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-project-report-group"},[_c('card',[_c('h2',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-flag",class:("flag-" + _vm.alarm)}),_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.flags.length > 0)?[_c('v-table',{staticClass:"table table-sm table-bordered table__flag"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_project")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_type")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_milestone")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_fsubtype")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_impact")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_mitigation")))])],1)],1),_c('v-tbody',_vm._l((_vm.flags),function(f){return _c('v-tr',{key:f.id},[(_vm.normal)?[_c('v-td',{staticClass:"table__flag_title"},[_c('router-link',{attrs:{"to":{
                                        name: 'ProjectDisplay',
                                        params: { project_id: f.id }
                                    }}},[_vm._v(_vm._s(f.title))])],1),_c('v-td',{staticClass:"table__flag_type"},[_vm._v(_vm._s(f.project_type_name))]),_c('v-td',{staticClass:"text-center table__flag_milestone"},[(
                                        Array.isArray(
                                            f.project_milestones
                                        ) && f.project_milestones.length > 0
                                    )?_c('v-status-milestone',{attrs:{"milestone":f.project_milestones[0]}}):[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_subtype"},[_vm._v("---")]),_c('v-td',{staticClass:"table__flag_impact"},[_vm._v("---")]),_c('v-td',{staticClass:"table__flag_how"},[_vm._v("---")])]:[_c('v-td',{staticClass:"table__flag_title"},[_c('router-link',{attrs:{"to":{
                                        name: 'ProjectDisplay',
                                        params: { project_id: f.project.id }
                                    }}},[_vm._v(_vm._s(f.project.title))])],1),_c('v-td',{staticClass:"table__flag_type"},[_vm._v(_vm._s(f.project.project_type_name))]),_c('v-td',{staticClass:"text-center table__flag_milestone"},[(
                                        Array.isArray(
                                            f.project.project_milestones
                                        ) &&
                                            f.project.project_milestones
                                                .length > 0
                                    )?_c('v-status-milestone',{attrs:{"milestone":f.project.project_milestones[0]}}):[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_subtype"},[_vm._v(_vm._s(_vm.flagSubtypes[f.subtype])+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.flagStatus[f.status])+" ")])]),_c('v-td',{staticClass:"table__flag_impact"},[_c('v-newlines',{attrs:{"text":f.description}})],1),_c('v-td',{staticClass:"table__flag_how"},[_c('v-newlines',{attrs:{"text":f.description2}})],1)]],2)}),1)],1)]:_c('div',{staticClass:"alert alert-outline alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("project.group_no_projects_in_this_group"))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
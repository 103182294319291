<script>
export default {
    props: {
        context: {},
        baseCurrency: {}
    }
};
</script>

<template>
    <modal size="xxl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <div>{{ $t("vouchers.show_voucher_ec_line_details") }}</div>
        </template>

        <template slot="default">
            <v-table class="table table-mg">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("vouchers.tbl_hdr_voucher_number") }}</v-th>
                        <v-th>{{ $t("vouchers.tbl_hdr_post_nr") }}</v-th>
                        <v-th>{{ $t("vouchers.tbl_hdr_entry_type") }}</v-th>
                        <v-th>{{ $t("vouchers.tbl_hdr_supp_invoice") }}</v-th>
                        <v-th>{{ $t("vouchers.tbl_hdr_date") }}</v-th>
                        <v-th>{{ $t("vouchers.tbl_hdr_description") }}</v-th>
                        <v-th class="text-right">{{
                            $t("vouchers.tbl_hdr_amount")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("vouchers.tbl_hdr_amount_in_base_currency")
                        }}</v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="(l, i) in context" :key="i">
                        <v-td>{{ l.voucherNumber }}</v-td>
                        <v-td>{{ l.entryNumber }}</v-td>
                        <v-td>{{ l.entryType }}</v-td>
                        <v-td>
                            <template v-if="'supplierInvoiceNumber' in l">
                                {{ l.supplierInvoiceNumber }}
                            </template>
                            <template v-else>---</template>
                        </v-td>
                        <v-td>{{ $d(new Date(l.date), "short") }}</v-td>
                        <v-td>{{ l.text }}</v-td>

                        <v-td class="text-right">{{
                            $n(l.amount, {
                                style: "currency",
                                currency: l.currency,
                                currencyDisplay: "code"
                            })
                        }}</v-td>
                        <v-td class="text-right">{{
                            $n(l.amountInBaseCurrency, "twodec")
                        }}</v-td>
                    </v-tr>
                </v-tbody>
            </v-table>

            <pre v-if="false">{{ context }}</pre>
        </template>
    </modal>
</template>

<script>
import { FlagTypesIcons } from '@/constants/Planning.const';

export default {
    props: {
        flags: {}
    },
    computed: {
        iconTypes() {
            return FlagTypesIcons;
        }
    }
};
</script>

<template>
    <div>
        <ul>
            <li v-for="f in flags" :key="f.id">
                <template v-if="f.subtype === 'flag'">
                    <i :class="iconTypes[f.flag_type]"></i> Mie: hvad her? :)
                </template>
                <template v-else="f.subtype === 'timeline_change'">

                </template>
            </li>
        </ul>
        
    </div>
</template>

<script>
export default {
    props: {
        context: {}
    },
    computed: {
        v() {
            return this.context;
        }
    }
};
</script>

<template>
    <modal size="xxl" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <div>{{ $t("vouchers.booked_voucher_details") }}</div>
        </template>

        <template slot="default">
            <div class="row">
                <div class="col">
                    <dl>
                        <dt>{{ $t("vouchers.amount") }}</dt>
                        <dd>
                            {{
                                $n(v.amount, {
                                    style: "currency",
                                    currency: v.currency,
                                    currencyDisplay: "code"
                                })
                            }}
                            <span class="text-muted"
                                >({{
                                    $n(v.orig_amount, {
                                        style: "currency",
                                        currency: v.orig_currency,
                                        currencyDisplay: "code"
                                    })
                                }})</span
                            >
                        </dd>

                        <dt>{{ $t("vouchers.description") }}</dt>
                        <dd>{{ v.description }}</dd>

                        <dt>{{ $t("vouchers.date") }}</dt>
                        <dd>{{ $d(new Date(v.voucher_date), "short") }}</dd>
                    </dl>
                </div>
                <div class="col">
                    <dl>
                        <dt>{{ $t("vouchers.tax_year") }}</dt>
                        <dd>{{ v.voucher_tax_year }}</dd>

                        <dt>{{ $t("vouchers.ec_number") }}</dt>
                        <dd>{{ v.ec_voucher_number }}</dd>

                        <dt>{{ $t("vouchers.ec_connection") }}</dt>
                        <dd>{{ v.ec_connection.name }}</dd>
                    </dl>
                </div>
            </div>

            <!-- listing -->

            <hr />
            <h1 class="mb-4">{{ $t("vouchers.cost_allocation") }}</h1>
            <div
                v-for="ma in context.milestone_allocations"
                :key="ma.id"
                class="cost-struct__container"
            >
                <div class="cost-struct milestone-m-ident">
                    <div class="cost-struct__ldata ">
                        M{{ ma.project_milestone.m_ident }}
                        <span
                            class="date-specs"
                            v-if="ma.project_milestone.start_date"
                        >
                            {{
                                $d(
                                    new Date(ma.project_milestone.start_date),
                                    "short"
                                )
                            }}
                            -
                            {{
                                $d(
                                    new Date(ma.project_milestone.end_date),
                                    "short"
                                )
                            }}
                        </span>
                    </div>
                    <div class="cost-struct__money">
                        {{
                            $n(ma.amount, {
                                style: "currency",
                                currency: ma.currency,
                                currencyDisplay: "code"
                            })
                        }}
                    </div>
                </div>

                <div class="cost-struct milestone-details">
                    <div class="cost-struct__ldata">
                        <dl>
                            <dt>{{ $t("vouchers.milestone") }}</dt>
                            <dd>{{ ma.project_milestone.name }}</dd>

                            <dt>{{ $t("vouchers.own_note") }}</dt>
                            <dd><v-empty :text="ma.note" /></dd>
                        </dl>
                    </div>
                    <div class="cost-struct__rdata">
                        <div
                            v-for="ca in ma.category_allocations"
                            :key="ca.id"
                            class="cat-costs"
                        >
                            <div class="cat-costs__name">
                                {{ ca.voucher_category.name }}
                            </div>

                            <div class="cat-costs__money">
                                {{
                                    $n(ca.amount, {
                                        style: "currency",
                                        currency: ca.currency,
                                        currencyDisplay: "code"
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pre v-if="false">{{ context }}</pre>
        </template>
    </modal>
</template>

<style lang="scss">
.cost-struct {
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    justify-content: space-between;

    &__container {
        margin-bottom: 40px;
    }

    &__ldata {
        flex-grow: 3;
        .milestone-name {
        }

        .milestone-dates {
        }

        .cost-struct__note {
        }
    }

    &__rdata {
        flex-grow: 2;
    }

    &__money {
        text-align: right;
        flex: 0 0 200px;
    }

    &.milestone-m-ident {
        border-bottom: 2px solid $gray-500;

        font-size: 24px;
        font-weight: 600;
    }

    &.milestone-details {
        margin-top: 15px;
    }

    .cat-costs {
        display: flex;
        justify-content: space-between;

        &__name {
            padding-left: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            flex-grow: 1;
            border-bottom: 1px solid $gray-500;
        }
        &__money {
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid $gray-500;
            text-align: right;
            flex: 0 0 200px;
        }
    }

    .date-specs {
        color: $gray-700;
        margin-left: 20px;
        font-size: 14px;
        font-weight: normal;
    }
}
</style>

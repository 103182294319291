<script>
import VStatusMilestone from "@/components/Planning/Helpers/VStatusMilestone";

export default {
    props: {
        projects: {},
        noStage: {
            type: Boolean,
            default: false
        }
    },
    components: { VStatusMilestone }
};
</script>

<template>
    <div class="v-portfolio-stage-table">
        <div
            v-if="
                typeof projects === 'undefined' ||
                    (Array.isArray(projects) && projects.length == 0)
            "
        >
            <div class="v-portfolio-stage-table__empty_stage mx-4">
                {{ $t("project.no_projects_in_this_stage") }}
            </div>
        </div>
        <v-table class="table table-mg table-sm table-bordered" v-else>
            <v-thead>
                <v-tr>
                    <v-th class="v-portfolio-stage-table__title">{{
                        $t("project.title")
                    }}</v-th>
                    <v-th class="v-portfolio-stage-table__type">{{
                        $t("project.type")
                    }}</v-th>
                    <v-th class="v-portfolio-stage-table__status">
                        {{ $t("project.status") }}
                    </v-th>
                    <v-th class="v-portfolio-stage-table__pm">
                        {{ $t("project.project_manager") }}
                    </v-th>
                    <v-th
                        class="v-portfolio-stage-table__milestone text-center"
                        >{{ $t("project.milestone") }}</v-th
                    >
                    <v-th class="v-portfolio-stage-table__municipality">{{
                        $t("project.municipality")
                    }}</v-th>
                    <v-th class="v-portfolio-stage-table__website"
                        >{{ $t("project.website") }}
                    </v-th>
                </v-tr>
            </v-thead>
            <v-tbody>
                <v-tr v-for="p in projects" :key="p.id">
                    <template v-if="true">
                        <v-td>
                            <router-link
                                :to="{
                                    name: 'ProjectDisplay',
                                    params: { project_id: p.project.id }
                                }"
                                >{{ p.project.title }}</router-link
                            ></v-td
                        >
                        <v-td>{{ p.project.project_type_name }}</v-td>
                        <v-td>{{ p.project.status_name }}</v-td>
                        <v-td>{{ p.project.responsible_user.name }}</v-td>
                        <v-td class="text-center">
                            <template v-if="noStage">---</template>
                            <template v-else-if="'id' in p.project_milestone">
                                <v-status-milestone
                                    :milestone="p.project_milestone"
                                />
                            </template>
                            <template v-else>---</template>
                        </v-td>
                        <v-td>{{ p.project.municipality.name }}</v-td>
                        <v-td
                            ><template v-if="'project_website' in p.project">
                                <a :href="p.project.project_website.url">{{
                                    p.project.project_website.url
                                }}</a>
                            </template>
                            <template v-else>---</template></v-td
                        >
                    </template>
                </v-tr>
            </v-tbody>
        </v-table>
    </div>
</template>

<style lang="scss">
.v-portfolio-stage-table {
    &__empty_stage {
        border: 1px solid $gray-200;
        border-radius: 6px;
        padding: 10px;
        font-size: 11px;
        color: $gray-600;
    }

    &__title {
    }

    &__type {
        width: 100px;
    }
    &__status {
        width: 120px;
    }
    &__pm {
        width: 160px;
    }
    &__milestone {
        width: 120px;
    }
    &__municipality {
        width: 140px;
    }
    &__website {
        width: 160px;
    }
}
</style>

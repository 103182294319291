<script>
export default {
    props: {
        context: {}
    }
};
</script>

<template>
    <div class="v-m-allocations">
        <span v-for="c in context" class="badge badge-pill badge-primary"
            >M{{ c.project_milestone.m_ident }}</span
        >
    </div>
</template>

<style lang="scss">
.v-m-allocations {
    .badge {
        font-size: 12px;
        margin-right: 4px;
    }
}
</style>

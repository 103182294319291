var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"budget-modal",attrs:{"size":"full","show":true,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"sticky"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-bold"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.amount"))+": "+_vm._s(_vm.$n(_vm.context.amount, { style: "currency", currency: _vm.context.currency, currencyDisplay: "code" }))+" ")]),_c('div',{staticClass:"col d-flex justify-content-around"},[_c('div',{staticClass:"px-2 rounded",class:{
                        'text-primary': !_vm.hasGeneralError,
                        'bg-danger text-white': _vm.hasGeneralError
                    }},[(_vm.hasGeneralError)?[_c('i',{staticClass:"far fa-exclamation-triangle fa-fw"}),_vm._v(" "+_vm._s(_vm.$t("vouchers.error"))+" ")]:[_c('i',{staticClass:"far fa-check fa-fw"}),_vm._v(" "+_vm._s(_vm.$t("vouchers.no_errors"))+" ")]],2),_c('div',{staticClass:"px-2 rounded",class:{
                        'text-primary': _vm.isBalanced,
                        'bg-danger text-white': !_vm.isBalanced
                    }},[(_vm.isBalanced)?[_c('i',{staticClass:"far fa-equals fa-fw"}),_vm._v(" "+_vm._s(_vm.$t("vouchers.balanced"))+" ")]:[_c('i',{staticClass:"far fa-not-equal fa-fw"}),_vm._v(" "+_vm._s(_vm.$t("vouchers.unbalanced"))+" ")]],2)]),_c('div',{staticClass:"col text-right text-bold",class:{
                    'text-warning': _vm.totalRemaining > 0,
                    'text-danger': _vm.totalRemaining < 0,
                    'text-primary': _vm.totalRemaining == 0
                }},[_vm._v(" "+_vm._s(_vm.$t("vouchers.remaining"))+": "+_vm._s(_vm.$n(_vm.totalRemaining, { style: "currency", currency: _vm.context.currency, currencyDisplay: "code" }))+" ")])])]),_c('template',{slot:"header"},[(_vm.edit)?[_vm._v(" "+_vm._s(_vm.$t("vouchers.edit_voucher_entry"))+" ")]:[_vm._v(_vm._s(_vm.$t("vouchers.create_voucher_entry")))]],2),_c('template',{slot:"default"},[_c('div',{staticClass:"row voucher-data"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.title"))+" ")]),_vm._v(" "+_vm._s(_vm.context.description)+" ")]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.date_and_yax_year"))+" ")]),_vm._v(" "+_vm._s(_vm.context.voucher_date)+" "),_c('div',{staticClass:"text-info small"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.tax_year"))+": "+_vm._s(_vm.context.voucher_tax_year)+" ")])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.ec_number"))+" ")]),_vm._v(" "+_vm._s(_vm.context.ec_voucher_number)+" ")]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.project_title"))+" ")]),_vm._v(" "+_vm._s(_vm.project.title)+" ")])]),_c('div',{staticClass:"mb-4 text-center"},_vm._l((_vm.milestones),function(m){return _c('base-button',{attrs:{"size":"sm mb-3","type":m.id in _vm.entity ? 'danger' : 'primary'},on:{"click":function($event){return _vm.addMilestoneAllocation(m)}}},[_vm._v("M"+_vm._s(m.m_ident))])}),1),(false)?_c('h1',{staticClass:"row mb-4 mx-2"},[_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.amount"))+": "+_vm._s(_vm.$n(_vm.context.amount, { style: "currency", currency: _vm.context.currency, currencyDisplay: "code" }))+" ")]),_c('div',{staticClass:"col text-right",class:{
                    'text-warning': _vm.totalRemaining > 0,
                    'text-danger': _vm.totalRemaining < 0,
                    'text-primary': _vm.totalRemaining == 0
                }},[_vm._v(" "+_vm._s(_vm.$t("vouchers.remaining"))+": "+_vm._s(_vm.$n(_vm.totalRemaining, { style: "currency", currency: _vm.context.currency, currencyDisplay: "code" }))+" ")])]):_vm._e(),(Object.keys(_vm.entity).length > 0)?[_vm._l((_vm.entity),function(ma,k){return _c('div',{key:k,staticClass:"c-alloc"},[_c('div',{staticClass:"text-danger small mb-4 text-center"},[_c('span',{staticClass:"m-click",on:{"click":function($event){return _vm.removeElement(k)}}},[_c('i',{staticClass:"far fa-trash "}),_vm._v(" "+_vm._s(_vm.$t("vouchers.remove_entry")))]),_c('hr',{staticClass:"mt-3 mb-1"})]),_c('div',{staticClass:"c-alloc__milestone",class:{
                        'has-error': _vm.hasGeneralError,
                        'is-balanced': _vm.isBalanced
                    }},[_c('div',{staticClass:"c-alloc__mname"},[_vm._v(" M"+_vm._s(ma.project_milestone.m_ident)+": "+_vm._s(ma.project_milestone.name)+" "),_c('div',{staticClass:"small"},[_c('span',{staticClass:"m-click text-danger",on:{"click":function($event){return _vm.zeroCats(ma.project_milestone.id)}}},[_c('i',{staticClass:"far fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.$t("vouchers.zero_categories")))])])]),_c('div',{staticClass:"c-alloc__amount",class:{
                            'text-danger': _vm.hasGeneralError,
                            'text-primary': !_vm.hasGeneralError
                        }},[_c('span',{staticClass:"m-click mr-1",on:{"click":function($event){ma.amount = _vm.totalRemaining}}},[_c('i',{staticClass:"far fa-stamp"})]),_vm._v(" "+_vm._s(_vm.$n(_vm.totalRemaining, "twodec"))+" "),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.total_remaining_amount"))+" ")])]),_c('div',{staticClass:"c-alloc__amount",class:{
                            'text-danger': ma.remaining_amount < 0,
                            'text-warning': ma.remaining_amount != 0,
                            'text-primary':
                                ma.remaining_amount == 0 &&
                                ma.amount != 0 &&
                                ma.amount != ''
                        }},[_vm._v(" "+_vm._s(_vm.$n(ma.remaining_amount, "twodec"))+" "),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t( "vouchers.remaining_amount_this_milestone" ))+" ")])]),_c('div',{staticClass:"c-alloc__amount"},[_c('v-cost-input',{on:{"change":function($event){return _vm.recalculateGroup(ma.project_milestone.id)}},model:{value:(ma.amount),callback:function ($$v) {_vm.$set(ma, "amount", $$v)},expression:"ma.amount"}}),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t( "vouchers.amount_to_distribute_this_milestone" ))+" ")])],1)]),_c('div',{staticClass:"row"},_vm._l((_vm.categories),function(ca){return _c('div',{key:ca.id,staticClass:"col-4"},[_c('div',{staticClass:"c-cats",class:parseFloat(
                                    _vm.entity[ma.project_milestone.id][
                                        'category_allocations'
                                    ][ca.value]
                                ) > 0
                                    ? 'c-cats__nonzero'
                                    : 'c-cats__zero'},[_c('div',{staticClass:"c-cats__label"},[_vm._v(" "+_vm._s(ca.label)+" "),(false)?_c('div',[_vm._v(" "+_vm._s(_vm.entity[ma.project_milestone.id][ "category_allocations" ][ca.value])+" ")]):_vm._e()]),_c('div',{staticClass:"c-cats__input"},[_c('v-cost-input',{on:{"change":function($event){return _vm.recalculateGroup(
                                            ma.project_milestone.id
                                        )}},model:{value:(
                                        _vm.entity[ma.project_milestone.id][
                                            'category_allocations'
                                        ][ca.value]
                                    ),callback:function ($$v) {_vm.$set(_vm.entity[ma.project_milestone.id][
                                            'category_allocations'
                                        ], ca.value, $$v)},expression:"\n                                        entity[ma.project_milestone.id][\n                                            'category_allocations'\n                                        ][ca.value]\n                                    "}}),_c('button',{staticClass:"btn btn-sm text-primary",on:{"click":function($event){_vm.entity[ma.project_milestone.id][
                                            'category_allocations'
                                        ][ca.value] = ma.remaining_amount}}},[_c('i',{staticClass:"far fa-stamp"})])],1)])])}),0)])}),_c('div',{staticClass:"text-right mt-4"},[_c('base-button',{attrs:{"type":"secondary","size":"lg","outline":true},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),(_vm.edit)?_c('base-button',{attrs:{"type":"primary","size":"lg","disabled":_vm.hasGeneralError || !_vm.isBalanced},on:{"click":_vm.saveForm}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" "+_vm._s(_vm.$t("edit")))]):_c('base-button',{attrs:{"disabled":_vm.hasGeneralError || !_vm.isBalanced,"type":"primary","size":"lg"},on:{"click":_vm.saveForm}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("create")))])],1)]:[_c('div',{staticClass:"alert alert-warning alert-outline"},[_vm._v(" "+_vm._s(_vm.$t("vouchers.no_bookkeeping_and_distribution_data"))+" ")])],(false)?_c('pre',[_vm._v(_vm._s(_vm.entity))]):_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
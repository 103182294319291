<script>
export default {
    props: {
        milestone: {}
    }
}
</script>

<template>
    <div>
        <v-m :m="milestone" />
        
        <pre v-if="false">{{ milestone }}</pre>
    </div>
</template>
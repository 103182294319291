import i18n from "@/i18n";

const MilestoneStatus = {
    0: i18n.t("milestone_status.awaiting"),
    1: i18n.t("milestone_status.aw_confirmation"),
    5: i18n.t("milestone_status.done")
};

const FlagTypes = {
    orange: i18n.t("flagging.flagType_orange"),
    red: i18n.t("flagging.flagType_red")
};

const FlagTypesDetailed = {
    orange: i18n.t("flagging.flagType_orange_help_details"),
    red: i18n.t("flagging.flagType_red_help_details")
};

const FlagTypesIcons = {
    orange: "fas fa-flag text-warning",
    red: "fas fa-flag text-danger"
};

const FlagSubtypes = {
    flag: i18n.t("flagging.subtype_flag"),
    timeline_change: i18n.t("flagging.subtype_milestone_timeline_change")
};

const FlagStatus = {
    0: i18n.t("flagging.status_cancelled"),
    1: i18n.t("flagging.status_req_approval"),
    2: i18n.t("flagging.status_denied"),
    5: i18n.t("flagging.status_accepted")
};

const FlagAffect = {
    0: i18n.t("flagging.affect_do_not_affect"),
    1: i18n.t("flagging.affect_do_affect")
};

const LinkMetas = {
    "": i18n.t("project_links.meta_none"),
    projweb: i18n.t("project_links.meta_project_website")
};

export {
    MilestoneStatus,
    FlagTypes,
    FlagSubtypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagStatus,
    FlagAffect,
    LinkMetas
};

import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "vouchers",

    async GET_test(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/test`, {
            params: params
        });
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};

<script>
/**
 * Management of project milestones in a project (LOCAL CONTEXT)
 */
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";
import VoucherService from "@/services/VoucherService";
import VVoucherDetailsModal from "@/components/Costs/VVoucherDetailsModal";
import VAllocateModal from "@/components/Costs/VAllocateModal";

export default {
    name: "",
    props: {
        project_id: {}
    },
    components: { VVoucherDetailsModal, VAllocateModal },
    data() {
        return {
            loading: true,
            vouchers: [],
            meta: {},
            showVoucherDetailsModal: false,
            voucherContext: null,
            showAllocateModal: false,
            allocateContext: null,
            project: {}
        };
    },
    async created() {
        await this.fetchProject();
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                params["functionView"] = "inbox";
                params["status"] = "1";
                const { data } = await VoucherService.GET(params);
                this.vouchers = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        showVoucherDetails(ctx) {
            this.voucherContext = ctx;
            this.showVoucherDetailsModal = true;
        },
        /**
         * Close allocate & reload
         */
        closeReload() {
            this.showAllocateModal = false;
            this.fetchData();
            this.$notify({
                message: this.$t("vouchers.notify_new_entry_created"),
                timeout: 5000,
                icon: "far fa-check",
                type: "success"
            });
        },
        showAllocate(ctx) {
            this.allocateContext = ctx;
            this.showAllocateModal = true;
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <card body-classes="mx-0">
                    <div class="d-flex align-items-center mb-4 justify-content-between">
                        <h2 class="">{{ $t("vouchers.voucher_inbox") }}</h2>
                        <router-link class="btn btn-primary btn-sm" :to="{name: 'ProjectViewCosts', params: {project_id: project_id}}">{{ $t('vouchers.view_booked_costs') }}</router-link>
                    </div>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="vouchers.length > 0">
                        <v-table class="table table-mg">
                            <v-thead>
                                <v-tr>
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="created"
                                            >{{
                                                $t("vouchers.tbl_hdr_imported")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="ec_voucher_number"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_voucher_nr"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="voucher_date"
                                            >{{
                                                $t("vouchers.tbl_hdr_date")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="description"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_description"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-right"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="amount"
                                            >{{
                                                $t("vouchers.tbl_hdr_amount")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-right"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="orig_amount"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_orig_amount"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="ec_connection_id"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_ec_connection"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr v-for="v in vouchers" :key="v.id">
                                    <v-td
                                        >{{ $d(new Date(v.created), "long") }}
                                        <div class="small text-muted">
                                            {{ v.id }}
                                        </div></v-td
                                    >
                                    <v-td>{{ v.ec_voucher_number }}</v-td>
                                    <v-td>{{
                                        $d(new Date(v.voucher_date), "short")
                                    }}</v-td>
                                    <v-td
                                        >{{ v.description }}
                                        <i
                                            @click="
                                                showVoucherDetails(v.raw_data)
                                            "
                                            class="far m-click text-muted fa-info-square fa-fw fa-lg"
                                        ></i
                                    ></v-td>
                                    <v-td class="text-right">{{
                                        $n(v.amount, {
                                            style: "currency",
                                            currency: v.currency,
                                            currencyDisplay: "code"
                                        })
                                    }}</v-td>
                                    <v-td class="text-right">{{
                                        $n(v.orig_amount, {
                                            style: "currency",
                                            currency: v.orig_currency,
                                            currencyDisplay: "code"
                                        })
                                    }}</v-td>

                                    <v-td>{{ v.ec_connection.name }}</v-td>
                                    <v-td style="width: 180px">
                                        <base-button
                                            size="sm"
                                            type="primary"
                                            @click="showAllocate(v)"
                                            block
                                        >
                                            <i
                                                class="far fa-book-open fa-fw mr-1"
                                            ></i>
                                            {{ $t("vouchers.action_allocate") }}
                                        </base-button>
                                        <base-button
                                            size="sm"
                                            type="warning"
                                            outline
                                            block
                                        >
                                            <i
                                                class="far fa-times-square fa-fw mr-1"
                                            ></i>
                                            {{
                                                $t("vouchers.action_ignore")
                                            }}</base-button
                                        >
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                        <div class="paginator">
                            <base-pagination
                                :page-count="meta.pagination.total_pages"
                                :per-page="meta.pagination.per_page"
                                :value="meta.pagination.current_page"
                                @input="$handlePagination($event)"
                            />
                        </div>
                    </template>
                    <div class="alert alert-warning" v-else>
                        {{ $t("vouchers.no_vouchers_found") }}
                    </div>
                </card>
            </div>
        </div>
        <portal to="modals">
            <v-allocate-modal
                :context="allocateContext"
                :project-id="project_id"
                :project="project"
                v-if="showAllocateModal"
                @close="showAllocateModal = false"
                @close-reload="closeReload"
            />
            <v-voucher-details-modal
                v-if="showVoucherDetailsModal"
                @close="showVoucherDetailsModal = false"
                :context="voucherContext"
            />
        </portal>
    </div>
</template>

<style lang="scss"></style>

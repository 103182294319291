<script>
import NumberParser from "@/libraries/NumberParser";

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {}
    },
    data() {
        return {
            focus: false,
            editingValue: ""
        };
    },
    computed: {
        displayValue() {
            if (this.focus) {
                // Display this during editing
                return this.editingValue;
            } else {
                // Show formatted otherwise
                return this.$n(this.value, "twodec");
            }
        }
    },
    watch: {
        value(newVal, oldVal) {
            console.log("VCI: WATCH ", newVal, oldVal);
            if (!this.focus && newVal !== oldVal && newVal !== "") {
                console.log("VCI: WATCH => emit @change", newVal);
                this.$emit("change", newVal);
            }
        }
    },
    methods: {
        handleInput(e) {
            // saving what's typed in
            this.editingValue = e.target.value;
        },
        handleFocus() {
            this.focus = true;
            // On editing -> format
            if (this.value === "" || this.value == 0) {
                this.editingValue = "";
            } else {
                this.editingValue = this.$n(this.value, "twodec");
            }
        },
        handleBlur() {
            // Save formatted and unformatted to v-model
            let parser = new NumberParser(this.$i18n.locale);
            let val = parser.parse("" + this.editingValue);
            console.log(
                "VCI: handleBlur",
                this.$i18n.locale,
                this.editingValue,
                val
            );
            this.focus = false;
            if (isNaN(val)) {
                this.$emit("input", 0);
                console.log("VCI: handleBlur emit = empty");
            } else {
                this.$emit("input", val);
                console.log("VCI: handleBlur emit = ", val);
            }
        }
    }
};
</script>

<template>
    <input
        type="text"
        :disabled="disabled"
        :value="displayValue"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
    />
</template>
